import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import SEO from '../../components/atoms/seo'
import LpBottom01 from '../../components/organisms/lpBottom01'
import LpDownload01B from '../../components/organisms/lpDownload01B'
import LpFeature01 from '../../components/organisms/lpFeature01'
import LpFooter01 from '../../components/organisms/lpFooter01'
import LpFunction01 from '../../components/organisms/lpFunction01'
import LpFv01B from '../../components/organisms/lpFv01B'
import LpMerit01 from '../../components/organisms/lpMerit01'
import LpPlan01 from '../../components/organisms/lpPlan01'
import LpSecurity01 from '../../components/organisms/lpSecurity01'
import LpSupport01 from '../../components/organisms/lpSupport01'
import LpWhatis01 from '../../components/organisms/lpWhatis01'
import BreakPoints from '../../styles/breakPoints'
import LpCta01 from '../../components/organisms/lpCta01'
import LpHeader01 from '../../components/organisms/lpHeader01'

const Wrapper = styled.div`
  .arr-down {
    display: flex;
    position: relative;
    justify-content: center;
    position: relative;
    img {
      position: absolute;
      top: -40px;
      z-index: 1;
    }
  }
  ${BreakPoints.medium} {
    .arr-down {
      img {
        top: -20px;
        width: 12px;
      }
    }
  }
`

/**
 * キンクラLP-01-B
 * @constructor
 */
const Lp01BIndexPage = () => (
  <Wrapper>
    {/** ファーストビューB */}
    <SEO
      title="キンクラ"
      description="客先常駐特化型のクラウド勤怠管理システムならキンクラ。客先常駐、派遣での社員の勤怠をラクラク一元管理。キンクラなら働き方改革にもピッタリマッチします。"
    />
    <Helmet>
      <title>
        キンクラ | 勤怠をラクラク一元管理 -
        客先常駐特化型のクラウド勤怠管理システム
      </title>
    </Helmet>
    <LpHeader01 signUpLink="https://sign-up.kintaicloud.jp/sign-up/?utm_source=lp&utm_medium=button&tran_source=lpb" />
    <LpFv01B />
    <LpCta01 signUpLink="https://sign-up.kintaicloud.jp/sign-up/?utm_source=lp&utm_medium=button&tran_source=lpb" />
    <div className="arr-down">
      <img
        src="/images/lp/lp01/lpArr01.png"
        alt="下にスクロールしてキンクラの詳細をご覧ください"
      />
    </div>
    <LpWhatis01 />
    <LpFeature01 />
    <LpMerit01 />
    <LpCta01 signUpLink="https://sign-up.kintaicloud.jp/sign-up/?utm_source=lp&utm_medium=button&tran_source=lpb" />
    <LpFunction01 />
    <LpSupport01 />
    <LpSecurity01 />
    <LpPlan01 />
    <LpBottom01 />
    <LpCta01 signUpLink="https://sign-up.kintaicloud.jp/sign-up/?utm_source=lp&utm_medium=button&tran_source=lpb" />
    <LpDownload01B />
    <LpFooter01 />
  </Wrapper>
)

export default Lp01BIndexPage

export const pageQuery = graphql`
  query Lp01BIndexPages {
    site {
      siteMetadata {
        title
      }
    }
  }
`
